import withRouter from "./withRouter";
import React from "react";

function TitleHeader(props) {
  const path = props.location.pathname.replace(/\/+$/, "").slice(1);
  let title = "";
  if (path === "") {
    title = "TEATRO LUMINARIA";
  } else if (path === "teatroingles") {
    title = "TEATRO EN INGLÉS";
  } else if (path === "teatro") {
    title = "TEATRO EN CASTELLANO";
  } else if (path === "acall") {
    title = "A CALL TO ADVENTURE";
  } else if (path === "atrapada") {
    title = "#ATRAPADA!";
  } else if (path === "beeyourself") {
    title = "BEE YOURSELF!";
  } else if (path === "blacklight") {
    title = "BLACKLIGHT SYMPHONIES";
  } else if (path === "blacklight2") {
    title = "BLACKLIGHT SYMPHONIES: BILLY GOES WILD";
  } else if (path === "bouchontraveller") {
    title = "INSPECTOR BOUCHON, TIME TRAVELLER";
  } else if (path === "emotion") {
    title = "E-MOTION";
  } else if (path === "quest") {
    title = "QUEST FOR A SUPERHERO";
  } else if (path === "storytelling") {
    title = "STORYTELLING TIME! A DOG'S TALE";
  } else if (path === "trapped") {
    title = "#TRAPPED!";
  } else if (path === "violet") {
    title = "VIOLET, THE PLAYFUL CAT";
  } else if (path === "sinfonias") {
    title = "SINFONÍAS DE LUZ NEGRA";
  } else if (path === "sinfonias2") {
    title = "SINFONÍAS DE LUZ NEGRA: BILLY DESATADO";
  } else if (path === "bouchonviajero") {
    title = "INSPECTOR BOUCHON, VIAJERO DEL TIEMPO";
  } else if (path === "cuentacuentos") {
    title = "CUENTACUENTOS";
  } else if (path === "talleres") {
    title = "TALLERES";
  } else if (path === "compania") {
    title = "COMPAÑÍA";
  } else if (path === "contacto") {
    title = "CONTACTO";
  } else if (path === "avisolegal") {
    title = "AVISO LEGAL";
  } else if (path === "cookies") {
    title = "POLÍTICA DE COOKIES";
  } else if (path === "packahorro") {
    title = "PACKS DE AHORRO";
  } else if (path === "infoCovid") {
    title = "INFORMACIÓN SOBRE MEDIDAS COVID-19";
  } else if (path === "regalo/sinfonias") {
    title = "REGALO - SINFONÍAS DE LUZ NEGRA";
  } else if (path === "regalo/sinfonias2") {
    title = "REGALO - SINFONÍAS DE LUZ NEGRA 2: BILLY DESATADO";
    //BLOG
  } else if (path === "blog") {
    title = "BLOG";
  } else if (path === "blog/bienvenidos-blog") {
    title = "BIENVENIDOS AL BLOG";
  } else if (path === "blog/innovacion-teatro-aprendizaje-ingles") {
    title = "INNOVACIÓN EN EL TEATRO Y APRENDIZAJE DE INGLÉS";
  } else if (path === "blog/juegos-cortos-clase-ingles") {
    title = "TOP 5 JUEGOS CORTOS PARA TU CLASE DE INGLÉS";
  } else if (path === "blog/lesson-plan-creatividad-ingles-chefcraft") {
    title = "LESSON PLAN: CREATIVIDAD EN INGLÉS CON CHEF CRAFT";
  } else if (path === "blog/desarrollo-lenguaje-utero-1") {
    title = "EL DESARROLLO DEL LENGUAJE (PARTE 1)";
  } else if (path === "blog/desarrollo-lenguaje-nacimiento-2") {
    title = "EL DESARROLLO DEL LENGUAJE (PARTE 2)";
  } else if (
    path === "blog/la-importancia-del-teatro-en-ingles-para-colegios-parte-1"
  ) {
    title = "LA IMPORTANCIA DEL TEATRO EN INGLÉS PARA COLEGIOS (PARTE 1)";
  } else if (
    path === "blog/la-importancia-del-teatro-en-ingles-para-colegios-parte-2"
  ) {
    title = "LA IMPORTANCIA DEL TEATRO EN INGLÉS PARA COLEGIOS (PARTE 2)";
  } else if (path === "blog/lesson-plan-xmas-role-play-santa") {
    title = "LESSON PLAN DE NAVIDAD: ROLE-PLAY CON SANTA";
  } else if (path === "blog/guia-titeres-aula-parte1") {
    title = "GUÍA DE TÍTERES EN EL AULA (PARTE 1)";
  } else if (path === "blog/guia-titeres-aula-parte2") {
    title = "GUÍA DE TÍTERES EN EL AULA (PARTE 2)";
  } else {
    title = "ERROR 404 - PÁGINA NO ENCONTRADA";
  }

  return <h2 className={props.css}>{title}</h2>;
}

export default withRouter(TitleHeader);
