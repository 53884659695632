import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Asegúrate de crear este archivo para los estilos

const CookieConsent = () => {
  const [consent, setConsent] = useState(null);
  const [showMessage, setShowMessage] = useState(false); // Estado para mostrar el mensaje de limitación

  useEffect(() => {
    // Verifica si ya se ha guardado el consentimiento
    const savedConsent = localStorage.getItem('cookieConsent');
    if (savedConsent) {
      setConsent(savedConsent === 'true');
    }
  }, []);

  useEffect(() => {
    // Bloquear el scroll del cuerpo si no hay consentimiento
    if (consent === null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [consent]);

  const handleAccept = () => {
    setConsent(true);
    localStorage.setItem('cookieConsent', 'true'); // Guarda la aceptación
  };

  const handleReject = () => {
    setConsent(false);
    setShowMessage(true); // Muestra el mensaje de limitación
  };

  const closeMessage = () => {
    setShowMessage(false); // Cierra el mensaje de limitación
  };

  // Si el consentimiento es null, muestra el aviso de cookies
  if (consent === null) {
    return (
      <>
        <div className="cookie-overlay" />
        <div className="cookie-consent">
          <h2><strong>Aviso de Cookies</strong></h2>
          <img src="https://www.teatroluminaria.com/logo/luminariatransp.png" alt="Logotipo" className="cookie-logo" />
          <p>Nuestra página web utiliza cookies para mejorar tu experiencia de navegación. Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo al visitar nuestro sitio.</p>
          <p>Usamos cookies para:</p>
          <ul>
            <li className="cookie-small-text">Análisis de tráfico</li>
            <li className="cookie-small-text">Formularios de contacto</li>
            <li className="cookie-small-text">Publicidad</li>
            <li className="cookie-small-text">Redes sociales</li>
          </ul>
          <p>Al continuar navegando en nuestro sitio, aceptas el uso de cookies.<br/> Para más información, consulta nuestra <a href="https://teatroluminaria.com/cookies" target="_blank" rel="noopener noreferrer" className="cookie-link">Política de Cookies</a>.</p>
          <button onClick={handleAccept} className="cookie-button">Aceptar</button>
          <button onClick={handleReject} className="cookie-button">Rechazar</button>
        </div>
      </>
    );
  }

  // Si el consentimiento es false y se debe mostrar el mensaje de limitación
  if (showMessage) {
    return (
      <>
        <div className="cookie-overlay" onClick={closeMessage} />
        <div className="cookie-message-popup">
          <button className="cookie-close-button" onClick={closeMessage}>✖</button>
          <p>Algunas funciones pueden estar limitadas.</p>
        </div>
      </>
    );
  }

  return null; // No muestra nada si el consentimiento es true
};

export default CookieConsent;
